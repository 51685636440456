import request from '@/utils/axios.js'
// const isProd = import.meta.env.MODE=='production'
// 获取安全客专题页信息 id为专题页对应的id
export function getIscNews(id) {
  return request.get(`/other/aqk/subject?id=${id}`)
}

// 媒体接口
export function getMedia() {
  return request.get(`/promotion/isc2022/iscSchedule?t=1686157093165`)
}

//吉尼斯挑战
export function guinnessChallenge() {
  return request.get('/promotion/isc2023/guinnessChallenge?t=' + new Date().getTime())
}

// 大会日程
export function getIscSchedule() {
  return request.get('/promotion/isc2023/iscSchedule?t=' + new Date().getTime())
}

// 创新沙盒
export function getSandbox() {
  return request.get('/promotion/isc2023/sandbox?t=' + new Date().getTime())
}

// 获取 isc 2023 论坛介绍信息
export function getForun() {
  return request.get('/promotion/isc2023/forum?t=' + new Date().getTime())
}

// 生态伙伴
export function getEcologyPartner() {
  return request.get('/promotion/isc2023/partner?t=' + new Date().getTime())
}

// 重磅嘉宾
export function getKeyGuest() {
  return request.get('/promotion/isc2023/guest?t=' + new Date().getTime())
}

// 所有嘉宾
export function getAllGuest() {
  return request.get('/promotion/isc2023/allguest?t=' + new Date().getTime())
}

//2023首页页眉banner
export function getHeadLineBanner() {
  return request.get('/promotion/isc/headlineBanner?t=' + new Date().getTime())
}

// 创新沙盒决赛 sandbox2
export function getSandoxDecider() {
  return request.get('/promotion/isc2023/sandboxDecider?t=' + new Date().getTime())
}

// 招募页面
export function getRecruit(id) {
  return request.get(`portal/index/config?id=${id}&t=${new Date().getTime()}`);
}
